<template>
  <b-container fluid>
    <div class="row">
        <div class="col-md-12">
            <div class="card card-primary card-outline">
                <div class="card-header">
                    <h2 class="card-title">Termine</h2>
                </div>
                <div class="card-body">
                    <FullCalendar :options="calendarOptions" />
                </div>
            </div>
        </div>
    </div>
    
  </b-container>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
// import deLocale from '@fullcalendar/core/locales/de';
import bootstrapPlugin from '@fullcalendar/bootstrap';
export default {
    components: {
        FullCalendar // make the <FullCalendar> tag available
    },
    data() {
        return {
            events:[],
            calendarOptions: {
                timeZone: "Europe/Berlin",
                plugins: [ dayGridPlugin, bootstrapPlugin ],
                themeSystem: 'bootstrap',
                initialView: 'dayGridMonth',
                locale: 'de',
                firstDay: 1,
                buttonText: {
                    today: "Heute",
                    month: "Monat",
                    week: "Woche",
                    day: "Tag",
                    list: "Liste"
                },
                weekNumbers: true,
                events: [],
            },
            params:{
                per_page: 200,
            },
        }
    },

    methods:{
        getEntries()
        {
            this.axios
                .get("/calendars/display")
                .then((response) => {
                    this.calendarOptions.events = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },
    },

    created(){
        this.getEntries();
    }
}
</script>

<style>

</style>